<template>
  <div>
    <input type="file" @change="handleFileChange" />
    <button @click="uploadFile">上传</button>
  </div>
</template>
 
<script setup>
import { ref } from 'vue';
 
const selectedFile = ref(null);
 
const handleFileChange = (event) => {
  selectedFile.value = event.target.files[0];
};
 
const uploadFile = async () => {
  if (!selectedFile.value) {
    alert('请选择一个文件。');
    return;
  }
 
  const formData = new FormData();
  formData.append('file', selectedFile.value);
 
  try {
    const response = await fetch('YOUR_API_ENDPOINT', {
      method: 'POST',
      body: formData,
    });
    if (response.ok) {
      alert('上传成功！');
    } else {
      alert('上传失败。');
    }
  } catch (error) {
    alert('上传出现错误：', error.message);
  }
};
</script>