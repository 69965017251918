<template>
  <a-config-provider :locale="locale">
    <router-view/>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
export default {
  data(){
          return{
              locale: zhCN,
          }
      }
}
</script>
<style>

  @import './common/font.css';
  body,html{height:100%;overflow: hidden;}
  #app{height: 100%;}
</style>

